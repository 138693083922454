import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(52, 120, 247)',
        py: 2,
      }}
    >
      <Container>
        <Typography
          textAlign="center"
          fontSize={{
            xs: 12,
            md: 16,
          }}
          color="#ffffff"
        >
          {t('home.copyright')}
        </Typography>
      </Container>
    </Box>
  );
};
