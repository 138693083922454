import { CircularProgress } from '@mui/material';
import { useGetApplicationQuery } from '../../../../generated/graphql';
import { Navigate } from 'react-router-dom';
import { ApplicationShowView } from './ApplicationShowView';

export const ApplicationShowQuery = ({
  applicationId,
}: {
  applicationId: string;
}) => {
  const { data, loading, error } = useGetApplicationQuery({
    variables: {
      id: applicationId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data?.studentGetApplication) {
    return <Navigate to="/applications" />;
  }

  return <ApplicationShowView application={data.studentGetApplication} />;
};
