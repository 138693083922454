import { Grid } from '@mui/material';
import {
  InputType,
  StudentDataInput,
  StudentFragment,
  useUpdateStudentMutation,
} from '../../../../generated/graphql';
import { Translation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../../../utils';
import { Input } from '../../../../common';
import { LoadingButton } from '@mui/lab';
import { Dispatch, SetStateAction } from 'react';

export const ProfileStudentEdit = ({
  student,
  setShow,
}: {
  student: StudentFragment;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const [updateStudent, { loading }] = useUpdateStudentMutation();

  const methods = useForm<StudentDataInput>({
    defaultValues: {
      university: {
        id: student.university.id,
        name: student.university.name,
        code: student.university.code,
      },
      ...updateDefaultValuesWithData(
        extractDefaultValuesFromInputs(student.university.studentInputs),
        student.data,
      ),
    },
  });

  const onSubmit: SubmitHandler<StudentDataInput> = (data) => {
    updateStudent({
      variables: {
        data,
      },
      onCompleted() {
        setShow(true);
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Input
          input={{
            name: 'university',
            type: InputType.University,
            position: 0,
            label: 'Университет',
            params: {
              defaultValue: null,
              // resetOnChange: ['program', 'faculty', 'speciality'],
            },
            rules: {
              required: {
                value: true,
                message: 'Обязательное поле',
              },
            },
          }}
          disabled={loading}
          {...methods}
        />

        {student.university.studentInputs.map((input, index) => (
          <Input key={index} input={input} disabled={loading} {...methods} />
        ))}

        <Grid item xs={12}>
          <Translation>
            {(t) => (
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                {t('profile.student.save')}
              </LoadingButton>
            )}
          </Translation>
        </Grid>
      </Grid>
    </form>
  );
};
