import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { REGIONS } from './REGIONS';
import { useTranslation } from 'react-i18next';

export const InputRegion = ({
  input: { label, name, rules },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  const { t } = useTranslation();

  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{t(`input.region.${getValues(name)}`)}</div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error} fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} disabled={disabled}>
            {REGIONS.map((region_key) => (
              <MenuItem key={region_key} value={region_key}>
                {t(`input.region.${region_key}`)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
