import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { Navbar } from './Navbar/Navbar';
import { useEffect } from 'react';

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      !['/sign-in', '/'].includes(pathname.split('/').slice(0, 2).join('/'))
    ) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Navbar />
      <Toolbar />
      <Outlet />
    </Box>
  );
};
