import { useFragment } from '@apollo/client';
import {
  ServiceListItemFragment,
  ServiceListItemFragmentDoc,
} from '../../generated/graphql';

type Props = {
  match: {
    params: {
      serviceId?: string;
    };
  };
};

export const DynamicServiceBreadcrumb = ({
  match: {
    params: { serviceId },
  },
}: Props) => {
  const { data: serviceListItem } = useFragment<ServiceListItemFragment>({
    fragment: ServiceListItemFragmentDoc,
    fragmentName: 'ServiceListItem',
    from: {
      __typename: 'Service',
      id: serviceId,
    },
  });

  return <span>{serviceListItem.name || 'Услуга'}</span>;
};
