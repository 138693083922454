import FormLabel from '@mui/material/FormLabel';

import { InputProps } from '../../InputController';
import { FormControl, FormHelperText } from '@mui/material';

export const InputFile = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue },
  },
  register,
  formState: { errors },
  disabled,
}: InputProps) => {
  return (
    <FormControl required={rules.required?.value} error={!!errors[name]}>
      <FormLabel>{label}</FormLabel>
      <input
        {...register(
          'files',
          Object.fromEntries(
            Object.entries(rules).filter(([, value]) => value !== null),
          ),
        )}
        defaultValue={defaultValue}
        type="file"
        accept="image/x-png,image/jpeg,.pdf"
        multiple
        disabled={disabled}
      />
      <FormHelperText>{errors[name]?.message?.toString()}</FormHelperText>
    </FormControl>
  );
};
