import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const First = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: {
          xs: 'calc(100dvh - 56px)',
          sm: 'calc(100dvh - 64px)',
        },
        backgroundColor: 'rgb(52, 120, 247)',
      }}
    >
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Stack
          spacing={{
            xs: 6,
            md: 8,
          }}
          alignItems="center"
        >
          <Typography
            textAlign="center"
            color="white"
            fontSize={{
              xs: 40,
              sm: 60,
              md: 72,
            }}
            dangerouslySetInnerHTML={{
              __html: t('home.studentcenterservice'),
            }}
          />
          <Link
            component={RouterLink}
            to="/sign-in"
            color="#ffffff"
            textTransform="uppercase"
            fontSize={{
              xs: 32,
              md: 40,
            }}
          >
            {t('home.login')}
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};
