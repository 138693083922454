import { Box, FormLabel, Grid, Stack, Typography } from '@mui/material';
import { useGetCurrentUserQuery } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { ProfileStudent } from './ProfileStudent';

export const ProfileOverview = () => {
  const { t } = useTranslation();
  const { data } = useGetCurrentUserQuery();

  if (!data) {
    throw new Error('User is missing in cache');
  }

  const { iin, fullName, country, student } = data.studentGetCurrentUser;

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500}>
              {t('profile.user.title')}
            </Typography>
            <Box>
              <FormLabel component="legend">{t('profile.user.iin')}</FormLabel>
              <Typography>{iin}</Typography>
            </Box>
            <Box>
              <FormLabel component="legend">
                {t('profile.user.fullName')}
              </FormLabel>
              <Typography>{fullName}</Typography>
            </Box>
            <Box>
              <FormLabel component="legend">
                {t('profile.user.country')}
              </FormLabel>
              <Typography>{country}</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {student && <ProfileStudent student={student} />}
        </Grid>
      </Grid>
    </Box>
  );
};
