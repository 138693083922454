import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import { Checkbox, FormControlLabel } from '@mui/material';

export const InputCheckbox = ({
  input: { label, name, rules },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name) ? 'Да' : 'Нет'}</div>
      </>
    );
  }

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          rules={Object.fromEntries(
            Object.entries(rules).filter(([, value]) => value !== null),
          )}
          render={({ field }) => (
            <Checkbox
              size="small"
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              disabled={disabled}
            />
          )}
        />
      }
      label={label}
    />
  );
};
