import { useApolloClient } from '@apollo/client';
import {
  ServiceFragment,
  ServiceFragmentDoc,
} from '../../../../generated/graphql';
import { useMemo } from 'react';
import { ApplicationNewQuery } from './ApplicationNewQuery';
import { ApplicationNewView } from './ApplicationNewView';

export const ApplicationNewCache = ({ serviceId }: { serviceId: string }) => {
  const client = useApolloClient();

  const service = useMemo(() => {
    return client.readFragment<ServiceFragment>({
      id: `Service:${serviceId}`,
      fragment: ServiceFragmentDoc,
      fragmentName: 'Service',
    });
  }, [client, serviceId]);

  if (!service) {
    return <ApplicationNewQuery serviceId={serviceId} />;
  }

  return <ApplicationNewView service={service} />;
};
