import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Grid,
} from '@mui/material';
import { StudentDocumentFragment } from '../../generated/graphql';
import { LoadingButton } from '@mui/lab';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { StudentDocumentFormShow } from './StudentDocumentFormShow';
import { documentCategoryVar } from '../../config/apollo/client';

export const StudentDocumentDialogShow = ({
  studentDocument,
  handleEdit,
}: {
  studentDocument: StudentDocumentFragment;
  handleEdit: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    documentCategoryVar(null);
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{studentDocument.document.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <StudentDocumentFormShow
            data={studentDocument.data}
            inputs={studentDocument.document.inputs}
          />
          <Box>
            <Typography gutterBottom>Файлы</Typography>
            <Grid container spacing={1}>
              {studentDocument.files.map(({ name, signedUrl }, index) => (
                <Grid item xs={12} sm="auto" key={index}>
                  <Button
                    fullWidth
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    size="small"
                    href={signedUrl}
                    target="_blank"
                  >
                    {name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <Typography>Дата создания</Typography>
            <Typography>
              {format(parseISO(studentDocument.createdAt), 'd MMMM, HH:mm', {
                locale: ru,
              })}
            </Typography>
          </Box>
          <Box>
            <Typography>Дата изменения</Typography>
            <Typography>
              {format(parseISO(studentDocument.updatedAt), 'd MMMM, HH:mm', {
                locale: ru,
              })}
            </Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
          width={{
            xs: '100%',
            md: 'auto',
          }}
          spacing={1}
        >
          <Button fullWidth={isMobile} onClick={handleClose} variant="outlined">
            Закрыть
          </Button>
          <LoadingButton
            fullWidth={isMobile}
            onClick={handleEdit}
            variant="contained"
          >
            Редактировать
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
