import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Button, Container, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Navbar = () => {
  const { t } = useTranslation();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Container
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            component={RouterLink}
            to="/"
            variant="h6"
            sx={{
              flexGrow: 1,
              textDecoration: 'none',
              color: 'inherit',
              alignSelf: 'center',
            }}
          >
            SQKO
          </Typography>
          <Button component={RouterLink} to="/sign-in" color="inherit">
            {t('home.login')}
          </Button>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
