import { Grid } from '@mui/material';
import {
  InputType,
  StudentDataInput,
  StudentFragment,
} from '../../../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../../../utils';
import { Input } from '../../../../common';

export const ProfileStudentShow = ({
  student,
}: {
  student: StudentFragment;
}) => {
  const methods = useForm<StudentDataInput>({
    defaultValues: {
      university: {
        id: student.university.id,
        name: student.university.name,
        code: student.university.code,
      },
      ...updateDefaultValuesWithData(
        extractDefaultValuesFromInputs(student.university.studentInputs),
        student.data,
      ),
    },
  });

  return (
    <form noValidate>
      <Grid container spacing={2}>
        <Input
          input={{
            name: 'university',
            type: InputType.University,
            position: 0,
            label: 'Университет',
            params: {
              defaultValue: null,
              // resetOnChange: ['program', 'faculty', 'speciality'],
            },
            rules: {
              required: {
                value: true,
                message: 'Обязательное поле',
              },
            },
          }}
          show
          {...methods}
        />

        {student.university.studentInputs.map((input, index) => (
          <Input key={index} input={input} show {...methods} />
        ))}
      </Grid>
    </form>
  );
};
