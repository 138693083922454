import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import {
  DocumentCategory,
  StudentDocumentFragment,
} from '../../../../generated/graphql';
import { documentCategoryVar } from '../../../../config/apollo/client';

export const StudentDocumentOverview = ({
  studentDocuments,
}: {
  studentDocuments: StudentDocumentFragment[];
}) => {
  const handleClick = (documentCategory: DocumentCategory) => {
    documentCategoryVar(documentCategory);
  };

  if (studentDocuments.length === 0) {
    return (
      <Box p={2}>
        <Typography>Нет документов</Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {studentDocuments.map((studentDocument) => (
          <Grid key={studentDocument.id} item xs={12} md={6} lg={4} xl={3}>
            <Card variant="outlined">
              <CardHeader title={studentDocument.document.name} />
              <CardActions>
                <Button onClick={() => handleClick(studentDocument.category)}>
                  Открыть
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
