import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import { FormControl } from '@mui/material';

export const InputNumber = ({
  input: { label, name, rules },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name)}</div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error} fullWidth>
          <TextField
            {...field}
            type="number"
            label={label}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            required={rules.required?.value}
            disabled={disabled}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        </FormControl>
      )}
    />
  );
};
