import { useGetCurrentUserQuery } from '../../../../generated/graphql';
import { StudentDocumentOverview } from './StudentDocumentOverview';

export const StudentDocumentOverviewCache = () => {
  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  if (!data) {
    throw Error('CurrentUser is missing in cache');
  }

  if (!data.studentGetCurrentUser.student) {
    throw Error('Student is missing in cache');
  }

  return (
    <StudentDocumentOverview
      studentDocuments={data.studentGetCurrentUser.student.documents}
    />
  );
};
