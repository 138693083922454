import { LoadingButton } from '@mui/lab';
import {
  ServiceFragment,
  useCreateApplicationMutation,
} from '../../../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import { cacheApplicationsAdd, cacheApplicationCreate } from './utils';
import { useForm } from 'react-hook-form';
import { Input } from '../../../../common/Input';
import { extractDefaultValuesFromInputs } from '../../../../utils';
import { Box, Grid } from '@mui/material';

export const ApplicationNewView = ({
  service,
}: {
  service: ServiceFragment;
}) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      ...extractDefaultValuesFromInputs(service.inputs),
      documentCategories: [],
    },
    shouldUnregister: true,
  });

  const [createApplication, { loading }] = useCreateApplicationMutation({
    onCompleted(data) {
      cacheApplicationsAdd(data.studentCreateApplication);
      cacheApplicationCreate(data.studentCreateApplication);
      navigate(`/applications`);
    },
  });

  const onSubmit = (data: unknown) => {
    createApplication({
      variables: {
        serviceId: service.id,
        data: data,
      },
    });
  };

  return (
    <Box p={2}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          {service.inputs.map((input, index) => (
            <Input key={index} input={input} {...methods} />
          ))}
          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              Создать
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
