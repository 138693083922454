import { UseFormReturn } from 'react-hook-form';

import { InputFragment, InputType } from '../../generated/graphql';
import { INPUT_DICT } from './constants';
import { Grid } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InputProps extends UseFormReturn<any, object> {
  input: InputFragment;
  show?: boolean;
  disabled?: boolean;
}

export const InputController = ({ input, show, ...methods }: InputProps) => {
  if (show && input.type === InputType.File) {
    return null;
  }

  if (
    input.params.renderIfPresent &&
    !methods.watch(input.params.renderIfPresent)
  ) {
    return null;
  }

  if (input.params.renderIf) {
    const allConditionsMet = input.params.renderIf.every((condition) => {
      const parentInput = show
        ? methods.getValues(condition.name)
        : methods.watch(condition.name);

      return parentInput === condition.value;
    });

    if (!allConditionsMet) {
      return null;
    }
  }

  const InputComponent = INPUT_DICT[input.type];

  if (!InputComponent) {
    return <div>Input of type {input.type} cannot be rendered</div>;
  }

  return (
    <Grid item xs={12}>
      <InputComponent input={input} show={show} {...methods} />
    </Grid>
  );
};
