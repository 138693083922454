import { Box, Container, Grid } from '@mui/material';
import { SignInQr } from './components';
import { EgovMobileIntructionsDesktop } from './components';

export const SignInDesktop = () => (
  <Box flex={1}>
    <Container
      sx={{
        height: '100%',
        pt: 3,
      }}
    >
      <Grid container spacing={4} height="100%">
        <Grid item sm={12} md={5} lg={6} height="100%">
          <SignInQr />
        </Grid>
        <Grid item sm={12} md={7} lg={6} height="100%">
          <EgovMobileIntructionsDesktop />
        </Grid>
      </Grid>
    </Container>
  </Box>
);
