import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  FormLabel,
} from '@mui/material';
import {
  StageTransitionFragment,
  useGetApplicationQuery,
} from '../../../../../../generated/graphql';

export const StageActionButtonPaymentDialog = ({
  open,
  handleClose,
  applicationId,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  transitions: StageTransitionFragment[];
}) => {
  const { data } = useGetApplicationQuery({
    variables: {
      id: applicationId,
    },
  });

  if (!data) {
    throw Error('Cache error');
  }

  const {
    student: {
      data: { faculty, courseYear },
    },
    user: { iin, fullName },
  } = data.studentGetApplication.data;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Оплата</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormLabel>Прикрепление квитанции</FormLabel>
            <Typography>Будет добавлено в течение суток!</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              Инструкция по оплате через KASPI банк
            </Typography>
            <ol
              style={{
                paddingLeft: '20px',
              }}
            >
              <li>
                <Typography>Открыть приложение Kaspi.kz</Typography>
              </li>
              <li>
                <Typography>Перейти в Платежи</Typography>
              </li>
              <li>
                <Typography>Выбрать «Образование»</Typography>
              </li>
              <li>
                <Typography>Выбрать «Вузы и колледжи»</Typography>
              </li>
              <li>
                <Typography>Данные</Typography>
              </li>
              <ul
                style={{
                  paddingLeft: '20px',
                }}
              >
                <li>
                  <Typography>
                    Город: <b>Караганда</b>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Название учебного заведения:{' '}
                    <b>
                      Карагандинский экономический университет Казпотребсоюза
                    </b>{' '}
                    (г. Караганда, ул. Академическая, 9)
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Факультет: <b>{faculty.shortName} за общежитие</b>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Курс: <b>{courseYear}</b>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    ФИО студента: <b>{fullName}</b>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    ИИН студента: <b>{iin}</b>
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Сумма: <b>51 000 ₸</b> (за 3 месяца)
                  </Typography>
                </li>
              </ul>
            </ol>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
