import { DrawerLinks } from './DrawerLinks';
import { Box, Divider, Toolbar } from '@mui/material';
import { UserInfo } from './UserInfo';
// import { LanguageMenu } from './LanguageMenu';

export const DrawerContent = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Toolbar />
    <UserInfo />
    <Divider />
    <DrawerLinks handleDrawerToggle={handleDrawerToggle} />
    {/* <Box
        sx={{
          marginTop: 'auto',
          p: 1,
        }}
      >
        <LanguageMenu />
      </Box> */}
  </Box>
);
