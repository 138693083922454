import client from '../../../../../config/apollo/client';
import {
  ApplicationFragment,
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
} from '../../../../../generated/graphql';

export const cacheApplicationCreate = (application: ApplicationFragment) => {
  client.cache.writeQuery<GetApplicationQuery, GetApplicationQueryVariables>({
    query: GetApplicationDocument,
    variables: {
      id: application.id,
    },
    data: {
      studentGetApplication: application,
    },
  });
};
