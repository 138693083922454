import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
} from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

export const UserInfo = () => {
  const client = useApolloClient();

  const currentUser = useMemo(() => {
    const data = client.readQuery<
      GetCurrentUserQuery,
      GetCurrentUserQueryVariables
    >({
      query: GetCurrentUserDocument,
    });

    return data?.studentGetCurrentUser;
  }, [client]);

  if (!currentUser) {
    return null;
  }

  const { firstName, lastName, middleName } = currentUser;

  const initials = `${lastName} ${firstName[0]}.${middleName[0]}.`;

  return (
    <Box p={2}>
      <Typography>{initials}</Typography>
    </Box>
  );
};
