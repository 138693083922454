import img0 from '../../../../assets/0.jpg';
import img1 from '../../../../assets/1.jpg';
import img1_2 from '../../../../assets/1-2.jpg';
import img2 from '../../../../assets/2.jpg';
import img3 from '../../../../assets/3.jpg';
import img4 from '../../../../assets/4.jpg';
import img5 from '../../../../assets/5.jpg';
import img6 from '../../../../assets/6.jpg';
import img7 from '../../../../assets/7.jpg';
import img8 from '../../../../assets/8.jpg';
import img9 from '../../../../assets/9.jpg';

export const EGOV_MOBILE_INSTRUCTIONS = [
  {
    id: 1,
    text: 'Скачайте приложение eGov mobile',
    img: img0,
  },
  {
    id: 2,
    text: 'Откройте приложение',
    img: img1,
  },
  {
    id: 3,
    text: "Нажмите на кнопку 'Вход / Регистрация'",
    img: img1_2,
  },
  {
    id: 4,
    text: "Выбирите 'Выпустить новые'",
    img: img2,
  },
  {
    id: 5,
    text: "Нажмите 'Войти'",
    img: img3,
  },
  {
    id: 6,
    text: 'Введите Ваш номер телефона',
    img: img4,
  },
  {
    id: 7,
    text: 'Укажите Ваш ИИН',
    img: img5,
  },
  {
    id: 8,
    text: 'Разрешите доступ к вашей камере',
    img: img6,
  },
  {
    id: 9,
    text: 'Придумайте пароль для ЭЦП',
    img: img7,
  },
  {
    id: 10,
    text: 'Придумайте код доступа для приложения',
    img: img8,
  },
  {
    id: 11,
    text: "Нажмите на кнопку 'Подписать'",
    img: img9,
  },
];
