import {
  InputFragment,
  StudentDocumentDataFragment,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { Button, Grid } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const StudentDocumentCardShow = ({
  data,
  inputs,
}: {
  data: StudentDocumentDataFragment;
  inputs: InputFragment[];
}) => {
  const methods = useForm({
    defaultValues: updateDefaultValuesWithData(
      extractDefaultValuesFromInputs(inputs),
      data.data,
    ),
  });

  return (
    <form noValidate>
      <Grid container spacing={2}>
        {inputs.map((input, index) => (
          <Input key={index} input={input} show {...methods} />
        ))}
        <Grid item>
          <Grid container spacing={1}>
            {data.files.map(({ name, signedUrl }, index) => (
              <Grid item xs={12} sm="auto" key={index}>
                <Button
                  fullWidth
                  variant="outlined"
                  endIcon={<OpenInNewIcon />}
                  size="small"
                  href={signedUrl}
                  target="_blank"
                >
                  {name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
