import { useState } from 'react';
import {
  ButtonFragment,
  StageTransitionFragment,
} from '../../../../../../generated/graphql';
import { Button } from '@mui/material';
import { StageActionButtonCorrectionDialog } from './StageActionButtonCorrectionDialog';

export const StageActionButtonCorrection = ({
  applicationId,
  transitions,
  button,
}: {
  applicationId: string;
  transitions: StageTransitionFragment[];
  button: ButtonFragment;
}) => {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleOpen();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant={
          button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'
        }
        color={
          button.color.toLowerCase() as
            | 'inherit'
            | 'primary'
            | 'secondary'
            | 'success'
            | 'error'
            | 'info'
            | 'warning'
        }
        fullWidth
      >
        {button.label}
      </Button>
      <StageActionButtonCorrectionDialog
        open={open}
        handleClose={handleClose}
        applicationId={applicationId}
        transitions={transitions}
      />
    </>
  );
};
