import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
} from '@apollo/client';
import { DocumentCategory } from '../../generated/graphql';

export const documentCategoryVar = makeVar<null | DocumentCategory>(null);

const link = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_SERVER,
  credentials: 'include',
});

const cache = new InMemoryCache({
  typePolicies: {
    Document: {
      keyFields: ['category'],
    },
    StudentDocument: {
      keyFields: ['category'],
    },
    InputRuleRegExp: {
      fields: {
        value: {
          read(value) {
            return new RegExp(value, 'i');
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
