import { useForm } from 'react-hook-form';
import { InputFragment } from '../../generated/graphql';
import { Box, Grid } from '@mui/material';
import { Input } from '../../common';

export const StudentDocumentFormNew = ({
  loading,
  handleSubmit,
  inputs,
}: {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (data: any) => void;
  inputs: InputFragment[];
}) => {
  const methods = useForm();

  const onSubmit = (data: unknown) => {
    handleSubmit(data);
  };

  return (
    <Box pt={1}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        id="STUDENT_DOCUMENT_NEW"
      >
        <Grid container spacing={2}>
          {inputs.map((input, index) => (
            <Input key={index} input={input} {...methods} disabled={loading} />
          ))}
        </Grid>
      </form>
    </Box>
  );
};
