import { Box, Grid } from '@mui/material';
import { Input } from '../../../common';
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  InputType,
  StudentDataInput,
  useCreateStudentMutation,
  useGetStudentInputsLazyQuery,
} from '../../../generated/graphql';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

export const StudentCreate = () => {
  const [createStudent, { loading, client }] = useCreateStudentMutation({
    onCompleted(data) {
      client.cache.updateQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >(
        {
          query: GetCurrentUserDocument,
        },
        (userData) => {
          if (!userData?.studentGetCurrentUser) return;

          return {
            studentGetCurrentUser: {
              ...userData.studentGetCurrentUser,
              student: data.studentCreateStudent,
            },
          };
        },
      );
    },
  });

  const [getStudentInputs, { data, loading: loadingStudentInputs }] =
    useGetStudentInputsLazyQuery();

  const methods = useForm<StudentDataInput>({
    shouldUnregister: true,
  });

  const universityWatch = methods.watch('university');

  useEffect(() => {
    if (universityWatch) {
      getStudentInputs({
        variables: {
          universityId: universityWatch.id,
        },
      });
    }
  }, [getStudentInputs, universityWatch]);

  const onSubmit: SubmitHandler<StudentDataInput> = (data) => {
    createStudent({
      variables: {
        data,
      },
    });
  };

  return (
    <Box py={2}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Input
            input={{
              name: 'university',
              type: InputType.University,
              position: 0,
              label: 'Университет',
              params: {
                defaultValue: null,
              },
              rules: {
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
              },
            }}
            disabled={loadingStudentInputs}
            {...methods}
          />

          {data &&
            data.studentGetStudentInputs.map((input, index) => (
              <Input key={index} input={input} {...methods} />
            ))}

          <Grid item xs={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
            >
              Создать профиль
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
