import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const NAVIGATION_LINKS = [
  {
    path: '/services',
    tValue: 'drawer.links.services',
  },
  {
    path: '/applications',
    tValue: 'drawer.links.applications',
  },
  {
    path: '/documents',
    tValue: 'drawer.links.documents',
  },
  {
    path: '/profile',
    tValue: 'drawer.links.profile',
  },
];

export const DrawerLinks = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        {NAVIGATION_LINKS.map(({ tValue, path }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              selected={pathname.includes(path)}
              component={Link}
              to={path}
              onClick={handleDrawerToggle}
            >
              <ListItemText primary={t(tValue)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
