import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  InputFragment,
  useCorrectApplicationFormMutation,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';

export const FormEdit = ({
  data,
  inputs,
  applicationId,
  handleShow,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  inputs: InputFragment[];
  applicationId: string;
  handleShow: () => void;
}) => {
  const methods = useForm({
    defaultValues: updateDefaultValuesWithData(
      extractDefaultValuesFromInputs(inputs),
      data,
    ),
  });

  const [correctApplicationForm, { loading }] =
    useCorrectApplicationFormMutation();

  const onSubmit = (data: unknown) => {
    correctApplicationForm({
      variables: {
        applicationId: applicationId,
        data: data,
      },
      update(cache, result) {
        const newFormSnapshot = result.data?.studentCorrectApplicationForm;

        if (!newFormSnapshot) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetApplication: {
                  ...data.studentGetApplication,
                  data: {
                    ...data.studentGetApplication.data,
                    form: newFormSnapshot,
                  },
                },
              };
            }
          },
        );
      },
      onCompleted() {
        handleShow();
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={2}>
            {inputs
              .filter((input) => data[input.name] !== undefined)
              .map((input, index) => (
                <Input
                  key={index}
                  input={input}
                  disabled={loading}
                  {...methods}
                />
              ))}
          </Grid>
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={loading}
          fullWidth
        >
          Сохранить
        </LoadingButton>
      </Stack>
    </form>
  );
};
