import { Container, Stack } from '@mui/material';
import { SignInQr } from './components';
import { EgovMobileIntructionsMobile } from './components';

export const SignInMobile = () => (
  <Container sx={{ flex: 1 }}>
    <Stack spacing={1} pt={2} height="100%">
      <SignInQr />
      <EgovMobileIntructionsMobile />
    </Stack>
  </Container>
);
