import { Box, Typography } from '@mui/material';
import { EGOV_MOBILE_INSTRUCTIONS } from '../../constants';

export const EgovMobileIntructionsMobile = () => (
  <Box
    sx={{
      width: 'calc(100vw - 16px)',
      height: '100%',
      // height: 'calc(100vh - 56px)', // Adjust height to account for mobile browser UI
    }}
  >
    <Box
      sx={{
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        pr: 2,
        height: '100%',
      }}
    >
      {EGOV_MOBILE_INSTRUCTIONS.map((instruction, index) => (
        <Box key={instruction.id}>
          <Box
            minHeight="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              textAlign="center"
              fontSize={{
                xs: '16px',
                sm: '20px',
              }}
              fontWeight={500}
            >
              Шаг {index + 1}. {instruction.text}
            </Typography>
          </Box>

          <Box height="calc(100dvh - 56px - 136px)">
            <img
              src={instruction.img}
              alt={`Шаг ${index + 1}`}
              style={{
                height: '100%',
                objectFit: 'cover', // Ensure the image scales appropriately
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
);
