import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Toolbar } from '@mui/material';
import { NoStudentAppBar } from './NoStudentAppBar';
import { useEffect } from 'react';

export const NoStudentLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!['/profile'].includes(pathname.split('/').slice(0, 2).join('/'))) {
      navigate('/profile', { replace: true });
    }
  }, []);

  return (
    <>
      <NoStudentAppBar />
      <Box>
        <Toolbar />
        <Container>
          <Outlet />
        </Container>
      </Box>
    </>
  );
};
