import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Button,
} from '@mui/material';
import { ApplicationDocumentFragment } from '../../../../../../generated/graphql';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { QrMultipleSigning } from '../../../../../QrSigning';

export const StageActionButtonSigningDialog = ({
  open,
  handleClose,
  applicationDocuments,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  applicationDocuments: ApplicationDocumentFragment[];
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Подписание</DialogTitle>
      <DialogContent sx={{ bgcolor: '#f3f3f3' }}>
        <Stack spacing={1}>
          {applicationDocuments.map((applicationDocument) => (
            <Button
              key={applicationDocument.id}
              variant="outlined"
              href={applicationDocument.signedURL}
              target="_blank"
              endIcon={<OpenInNewIcon />}
              size="large"
            >
              {applicationDocument.serviceDocument.name}
            </Button>
          ))}
          <QrMultipleSigning
            applicationDocuments={applicationDocuments.map(({ id }, index) => ({
              id,
              index,
            }))}
            handleClose={handleClose}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
