import { useFragment, useReactiveVar } from '@apollo/client';
import { documentCategoryVar } from '../../config/apollo/client';

import {
  DocumentCategory,
  DocumentFragment,
  DocumentFragmentDoc,
  StudentDocumentFragment,
  StudentDocumentFragmentDoc,
} from '../../generated/graphql';
import { StudentDocumentDialogNew } from './StudentDocumentDialogNew';
import { StudentDocumentDialogShow } from './StudentDocumentDialogShow';
import { StudentDocumentDialogEdit } from './StudentDocumentDialogEdit';
import { useState } from 'react';

const StudentDocumentDialog = ({
  documentCategory,
}: {
  documentCategory: DocumentCategory;
}) => {
  const { data: document, complete: documentComplete } =
    useFragment<DocumentFragment>({
      fragment: DocumentFragmentDoc,
      fragmentName: 'Document',
      from: {
        category: documentCategory,
        __typename: 'Document',
      },
    });

  const { data: studentDocument, complete: studentDocumentComplete } =
    useFragment<StudentDocumentFragment>({
      fragment: StudentDocumentFragmentDoc,
      fragmentName: 'StudentDocument',
      from: {
        category: documentCategory,
        __typename: 'StudentDocument',
      },
    });

  const [show, setShow] = useState(true);

  if (!documentComplete) {
    throw new Error(`Document with category ${documentCategory} not found!`);
  }

  const handleShow = () => {
    setShow(true);
  };

  const handleEdit = () => {
    setShow(false);
  };

  if (!studentDocumentComplete) {
    return <StudentDocumentDialogNew document={document} />;
  }

  return show ? (
    <StudentDocumentDialogShow
      studentDocument={studentDocument}
      handleEdit={handleEdit}
    />
  ) : (
    <StudentDocumentDialogEdit
      studentDocument={studentDocument}
      handleShow={handleShow}
    />
  );
};

export const StudentDocumentDialogController = () => {
  const documentCategory = useReactiveVar(documentCategoryVar);

  return (
    documentCategory && (
      <StudentDocumentDialog documentCategory={documentCategory} />
    )
  );
};
