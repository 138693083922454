import { useMediaQuery, useTheme } from '@mui/material';
import { SignInMobile } from './SignInMobile';
import { SignInDesktop } from './SignInDesktop';

export const SignIn = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <SignInMobile /> : <SignInDesktop />;
};
