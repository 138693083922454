import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ApolloProvider } from '@apollo/client';
import client from '../../config/apollo/client';
import { AuthProvider } from '../AuthProvider';
import { CookiesProvider } from 'react-cookie';

export const App = () => (
  <>
    <CssBaseline />
    <CookiesProvider defaultSetOptions={{}}>
      <ApolloProvider client={client}>
        <AuthProvider />
      </ApolloProvider>
    </CookiesProvider>
  </>
);
