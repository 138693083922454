import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  StudentDocumentFragment,
  StudentDocumentFragmentDoc,
  StudentDocumentSnapshotFragment,
} from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';
import { useState } from 'react';
import { useFragment } from '@apollo/client';
import { StudentDocumentCardEdit } from './StudentDocumentCardEdit';
import { StudentDocumentCardShow } from './StudentDocumentCardShow';

export const StudentDocumentCard = ({
  studentDocument,
  correction,
}: {
  studentDocument: StudentDocumentSnapshotFragment;
  correction?: {
    applicationId: string;
  };
}) => {
  const [edit, setEdit] = useState(false);

  const { data, complete } = useFragment<StudentDocumentFragment>({
    fragment: StudentDocumentFragmentDoc,
    fragmentName: 'StudentDocument',
    from: {
      category: studentDocument.data.category,
      __typename: 'StudentDocument',
    },
  });

  if (!complete) {
    throw Error('StudentDocument not found');
  }

  const handleEdit = () => {
    setEdit(true);
  };

  const handleShow = () => {
    setEdit(false);
  };

  const renderCorrectionMenu = () => {
    return edit ? (
      <CardActions>
        <Stack spacing={1} width="100%">
          <Button onClick={handleShow} variant="outlined">
            Назад
          </Button>
        </Stack>
      </CardActions>
    ) : (
      <CardActions>
        <Button onClick={handleEdit} variant="outlined" fullWidth>
          Изменить
        </Button>
      </CardActions>
    );
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">
              {studentDocument.data.name}
            </Typography>
            <VerificationStatus verification={studentDocument.verification} />
          </Box>
          {studentDocument.verification?.error && (
            <Alert
              severity={
                studentDocument.verification.error.corrected
                  ? 'warning'
                  : 'error'
              }
            >
              <AlertTitle>
                {studentDocument.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {studentDocument.verification.error.message}
            </Alert>
          )}
          {correction && edit ? (
            <StudentDocumentCardEdit
              data={studentDocument.data}
              inputs={data.document.inputs}
              applicationId={correction.applicationId}
              handleShow={handleShow}
            />
          ) : (
            <StudentDocumentCardShow
              data={studentDocument.data}
              inputs={data.document.inputs}
            />
          )}
        </Stack>
      </CardContent>
      {correction && renderCorrectionMenu()}
    </Card>
  );
};
