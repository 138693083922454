import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import { Button, FormControl, FormHelperText, FormLabel } from '@mui/material';
import {
  DocumentCategory,
  StudentDocumentFragment,
  StudentDocumentFragmentDoc,
} from '../../../../generated/graphql';
import { documentCategoryVar } from '../../../../config/apollo/client';
import { useEffect, useMemo } from 'react';
import { useFragment } from '@apollo/client';

export const InputStudentDocument = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue },
  },
  // show,
  // disabled,
  control,
  setValue,
  clearErrors,
}: InputProps) => {
  const documentCategory = useMemo<DocumentCategory>(
    () => name.replace('documents.', '') as DocumentCategory,
    [name],
  );

  const { complete } = useFragment<StudentDocumentFragment>({
    fragment: StudentDocumentFragmentDoc,
    fragmentName: 'StudentDocument',
    from: {
      category: documentCategory,
      __typename: 'StudentDocument',
    },
  });

  useEffect(() => {
    setValue(name, complete);

    if (complete) {
      clearErrors(name);
    }
  }, [complete, setValue, clearErrors, name]);

  const handleClick = () => {
    documentCategoryVar(documentCategory);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      render={({ fieldState }) => {
        return (
          <FormControl
            error={!!fieldState.error}
            required={rules.required?.value}
            fullWidth
          >
            <FormLabel>{label}</FormLabel>
            <Button
              variant="outlined"
              onClick={handleClick}
              color={fieldState.error ? 'error' : 'primary'}
            >
              {complete ? 'Открыть' : 'Добавить'}
            </Button>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
