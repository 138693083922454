import {
  QrFragment,
  useGetQrQuery,
  useGetQrSignQuery,
  useLoadQrQuery,
} from '../../generated/graphql';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { QrCode } from './QrCode';
import { LoadingButton } from '@mui/lab';

const ViewMobile = ({ qr, pending }: { qr: QrFragment; pending: boolean }) => {
  const handleClick = () => {
    window.location.href = qr.eGovMobileLaunchLink;
  };

  return (
    <LoadingButton
      onClick={handleClick}
      fullWidth
      size="large"
      loading={pending}
      loadingIndicator="На подписании…"
      variant={pending ? 'outlined' : 'contained'}
    >
      Открыть eGov Mobile
    </LoadingButton>
  );
};

const ViewDesktop = ({ qr, pending }: { qr: QrFragment; pending: boolean }) => {
  if (pending) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <QrCode qrCode={qr.qrCode} dataURL={qr.dataURL} signURL={qr.signURL} />
    </Box>
  );
};

const ViewController = ({
  qr,
  pending,
}: {
  qr: QrFragment;
  pending: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <ViewMobile qr={qr} pending={pending} />;
  }

  return <ViewDesktop qr={qr} pending={pending} />;
};

const GetSignQr = ({ qr, nonce }: { qr: QrFragment; nonce?: string }) => {
  const { loading, error } = useGetQrSignQuery({
    variables: {
      signURL: qr.signURL,
      nonce: nonce!,
    },
    skip: !nonce,
  });

  if (error) return `Error! ${error.message}`;

  return <ViewController qr={qr} pending={loading} />;
};

const LoadQr = ({ qr }: { qr: QrFragment }) => {
  const { data, error } = useLoadQrQuery({
    variables: {
      dataURL: qr.dataURL,
    },
  });

  if (error) return `Error! ${error.message}`;

  return <GetSignQr qr={qr} nonce={data?.loadQr} />;
};

const GetQrLoading = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <LoadingButton
        loading
        loadingIndicator="Открыть eGov Mobile"
        variant="outlined"
        fullWidth
        size="large"
      >
        Placeholder
      </LoadingButton>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export const GetQr = () => {
  const { loading, error, data } = useGetQrQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60 * 1000,
  });

  if (loading) return <GetQrLoading />;
  if (error) return `Error! ${JSON.stringify(error)}`;
  if (!data) return 'Smth went wrong';

  return <LoadQr qr={data.getQr} />;
};
