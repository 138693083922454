import { Box } from '@mui/material';
import { First, Second, Third, Fourth, Footer } from './components';

export const Home = () => (
  <Box>
    <First />
    <Second />
    <Third />
    <Fourth />
    <Footer />
  </Box>
);
