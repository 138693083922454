import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Home, SignIn } from '../../pages/guest';
import { GuestLayout } from '../../components/GuestLayout';

const guestRouter = createBrowserRouter([
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/sign-in',
        element: <SignIn />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export default guestRouter;
