import { Navigate, createBrowserRouter } from 'react-router-dom';
import { NoStudentLayout } from '../../components/NoStudentLayout';

import { StudentCreate } from '../../pages/student/student';

const noStudentRouter = createBrowserRouter([
  {
    path: '/',
    element: <NoStudentLayout />,
    children: [
      {
        path: '/profile',
        element: <StudentCreate />,
      },
      {
        path: '*',
        element: <Navigate to="/profile" />,
      },
    ],
  },
]);

export default noStudentRouter;
