import { Box, Typography } from '@mui/material';
import { QrAuthentication } from '../../../../../shared';

export const SignInQr = () => (
  <Box>
    <Typography
      textAlign="center"
      fontSize={20}
      fontWeight={500}
      display={{
        xs: 'none',
        sm: 'block',
      }}
    >
      eGov QR
    </Typography>
    <Typography
      textAlign="center"
      display={{
        xs: 'none',
        md: 'block',
      }}
    >
      Отсканируйте с помощью eGov mobile
    </Typography>
    <QrAuthentication />
  </Box>
);
