import { Stack, Typography, Box } from '@mui/material';
import { ApplicationStageUpdateFragment } from '../../generated/graphql';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const ApplicationHistory = ({
  updates,
}: {
  updates: ApplicationStageUpdateFragment[];
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} p={2} bgcolor="white">
      <Typography fontWeight="500">
        {t('application.card.headers.history')}
      </Typography>
      <Box>
        {updates.map((applicationStageUpdate) => (
          <Stack
            direction="row"
            spacing={2}
            key={applicationStageUpdate.createdAt}
          >
            <Typography>
              {/* 12 мая 2024, 15:00 */}
              {format(applicationStageUpdate.createdAt, 'd MMMM, HH:mm', {
                locale: ru,
              })}
            </Typography>
            <Typography>{applicationStageUpdate.message}</Typography>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
