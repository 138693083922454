import {
  StageTransitionFragment,
  StageTransitionType,
} from '../../../../generated/graphql';
import {
  StageTransitionButtonAlert,
  StageTransitionButtonDirect,
} from './components';

export const StageTransitionButton = ({
  applicationId,
  transition,
  disabled,
}: {
  applicationId: string;
  transition: StageTransitionFragment;
  disabled?: boolean;
}) => {
  const renderButton = () => {
    if (!transition.action) {
      return null;
    }

    switch (transition.action.type) {
      case StageTransitionType.Direct:
        return (
          <StageTransitionButtonDirect
            applicationId={applicationId}
            nextStageId={transition.nextStageId}
            button={transition.action.button}
            disabled={disabled}
          />
        );
      case StageTransitionType.Alert:
        if (!transition.alert) {
          throw new Error('No alert');
        }

        return (
          <StageTransitionButtonAlert
            applicationId={applicationId}
            nextStageId={transition.nextStageId}
            button={transition.action.button}
            alert={transition.alert}
          />
        );

      default:
        return null;
    }
  };

  return renderButton();
};
