import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  InputFragment,
  InputType,
  StudentDataFragment,
  useCorrectStudentFormMutation,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';

export const StudentCardEdit = ({
  data,
  inputs,
  applicationId,
  handleShow,
}: {
  data: StudentDataFragment;
  inputs: InputFragment[];
  applicationId: string;
  handleShow: () => void;
}) => {
  const methods = useForm<StudentDataFragment>({
    defaultValues: {
      university: {
        id: data.university.id,
        name: data.university.name,
        code: data.university.code,
      },
      ...updateDefaultValuesWithData(
        extractDefaultValuesFromInputs(inputs),
        data,
      ),
    },
  });

  const [correctStudentForm, { loading }] = useCorrectStudentFormMutation();

  const onSubmit = (data: StudentDataFragment) => {
    correctStudentForm({
      variables: {
        applicationId: applicationId,
        data: data,
      },
      update(cache, result) {
        const newStudentSnapshot = result.data?.studentCorrectStudentForm;

        if (!newStudentSnapshot) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetApplication: {
                  ...data.studentGetApplication,
                  data: {
                    ...data.studentGetApplication.data,
                    student: newStudentSnapshot,
                  },
                },
              };
            }
          },
        );

        cache.updateQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
          {
            query: GetCurrentUserDocument,
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetCurrentUser: {
                  ...data.studentGetCurrentUser,
                  student: {
                    ...data.studentGetCurrentUser.student!,
                    data: newStudentSnapshot.data,
                  },
                },
              };
            }
          },
        );
      },
      onCompleted() {
        handleShow();
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={2}>
            <Input
              input={{
                name: 'university',
                type: InputType.University,
                position: 0,
                label: 'Университет',
                params: {
                  defaultValue: null,
                },
                rules: {
                  required: {
                    value: true,
                    message: 'Обязательное поле',
                  },
                },
              }}
              disabled
              {...methods}
            />

            {inputs.map((input, index) => (
              <Input
                key={index}
                input={input}
                disabled={loading}
                {...methods}
              />
            ))}
          </Grid>
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={loading}
          fullWidth
        >
          Сохранить
        </LoadingButton>
      </Stack>
    </form>
  );
};
