import {
  ApplicationDocumentFragment,
  ButtonFragment,
  useGetApplicationQuery,
  useGetCurrentUserQuery,
} from '../../../../../../generated/graphql';
import { useMemo } from 'react';
import { StageActionButtonSigning } from './StageActionButtonSigning';
import { Button } from '@mui/material';

export const StageActionButtonSigningValidate = ({
  applicationId,
  button,
}: {
  applicationId: string;
  button: ButtonFragment;
}) => {
  const { data: currentUserData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  const { data: applicationData } = useGetApplicationQuery({
    fetchPolicy: 'cache-only',
    variables: {
      id: applicationId,
    },
  });

  if (!currentUserData || !applicationData) {
    throw Error('Cache error');
  }

  const { signedApplicationDocuments, unsignedApplicationDocuments } = useMemo<{
    signedApplicationDocuments: ApplicationDocumentFragment[];
    unsignedApplicationDocuments: ApplicationDocumentFragment[];
  }>(
    () =>
      applicationData?.studentGetApplication.documents.reduce<{
        signedApplicationDocuments: ApplicationDocumentFragment[];
        unsignedApplicationDocuments: ApplicationDocumentFragment[];
      }>(
        (acc, cur) => {
          const isSigner = cur.signers.find(
            ({ iin }) => iin === currentUserData?.studentGetCurrentUser.iin,
          );

          if (!isSigner) {
            return acc;
          }

          if (isSigner.signed) {
            return {
              ...acc,
              signedApplicationDocuments: [
                ...acc.signedApplicationDocuments,
                cur,
              ],
            };
          } else {
            return {
              ...acc,
              unsignedApplicationDocuments: [
                ...acc.unsignedApplicationDocuments,
                cur,
              ],
            };
          }
        },
        {
          signedApplicationDocuments: [],
          unsignedApplicationDocuments: [],
        },
      ),
    [
      applicationData?.studentGetApplication.documents,
      currentUserData?.studentGetCurrentUser.iin,
    ],
  );

  if (
    signedApplicationDocuments.length + unsignedApplicationDocuments.length ===
    0
  ) {
    return null;
  }

  if (unsignedApplicationDocuments.length === 0) {
    return (
      <Button variant="text" fullWidth disabled>
        Подписано
      </Button>
    );
  }

  return (
    <StageActionButtonSigning
      applicationId={applicationId}
      button={button}
      applicationDocuments={unsignedApplicationDocuments}
    />
  );
};
