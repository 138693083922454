import { InputFragment } from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { Grid } from '@mui/material';

export const FormShow = ({
  data,
  inputs,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  inputs: InputFragment[];
}) => {
  const methods = useForm({
    defaultValues: updateDefaultValuesWithData(
      extractDefaultValuesFromInputs(inputs),
      data,
    ),
  });

  return (
    <form noValidate>
      <Grid container spacing={2}>
        {inputs
          .filter((input) => data[input.name] !== undefined)
          .map((input, index) => (
            <Input key={index} input={input} show {...methods} />
          ))}
      </Grid>
    </form>
  );
};
