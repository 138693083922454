import { Button, Stack, Typography } from '@mui/material';
import { StudentFragment } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { ProfileStudentShow } from './ProfileStudentShow';
import { ProfileStudentEdit } from './ProfileStudentEdit';

const Heading = ({
  show,
  handleToggle,
}: {
  show: boolean;
  handleToggle: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Typography fontSize={18} fontWeight={500}>
        {t('profile.student.title')}
      </Typography>

      <Button onClick={handleToggle}>
        {t(`profile.student.${show ? 'edit' : 'back'}`)}
      </Button>
    </Stack>
  );
};

export const ProfileStudent = ({ student }: { student: StudentFragment }) => {
  const [show, setShow] = useState(true);

  const handleToggle = () => {
    setShow((prevShow) => !prevShow);
  };

  return (
    <Stack spacing={1}>
      <Heading handleToggle={handleToggle} show={show} />

      {show ? (
        <ProfileStudentShow student={student} />
      ) : (
        <ProfileStudentEdit student={student} setShow={setShow} />
      )}
    </Stack>
  );
};
