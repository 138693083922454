import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useLogoutMutation } from '../generated/graphql';

import { useTranslation } from 'react-i18next';

export const NoStudentAppBar = () => {
  const { t } = useTranslation();
  const [logout, { loading, client }] = useLogoutMutation({
    onCompleted() {
      client.resetStore();
    },
  });

  const handleClick = () => {
    logout();
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="h6" noWrap component="div" alignSelf="center">
            SQKO Student
          </Typography>
          <Button color="inherit" onClick={handleClick} disabled={loading}>
            {t('appbar.exit')}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
