import { LoadingButton } from '@mui/lab';
import {
  StageTransitionAlertFragment,
  ButtonFragment,
  useUpdateApplicationMutation,
} from '../../../../../../generated/graphql';
import { useState } from 'react';
import { StageTransitionButtonAlertDialog } from './StageTransitionButtonAlertDialog';

export const StageTransitionButtonAlert = ({
  applicationId,
  nextStageId,
  button,
  alert,
}: {
  applicationId: string;
  nextStageId: string;
  button: ButtonFragment;
  alert: StageTransitionAlertFragment;
}) => {
  const [updateApplication, { loading }] = useUpdateApplicationMutation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleOpen();
  };

  const handleUpdateApplication = () => {
    updateApplication({
      variables: {
        id: applicationId,
        stageId: nextStageId,
      },
    });
  };

  return (
    <>
      <LoadingButton
        onClick={handleClick}
        loading={loading}
        variant={
          button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'
        }
        fullWidth
        color={
          button.color.toLowerCase() as
            | 'error'
            | 'inherit'
            | 'primary'
            | 'secondary'
            | 'success'
            | 'info'
            | 'warning'
        }
      >
        {button.label}
      </LoadingButton>
      <StageTransitionButtonAlertDialog
        open={open}
        handleClose={handleClose}
        handleUpdateApplication={handleUpdateApplication}
        loading={loading}
        alert={alert}
      />
    </>
  );
};
