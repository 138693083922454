import { CircularProgress } from '@mui/material';
import { useGetServicesQuery } from '../../../../generated/graphql';
import { ServiceOverviewView } from './ServiceOverviewView';

export const ServiceOverviewQuery = () => {
  const { data, loading, error } = useGetServicesQuery();

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data) {
    return <div>Error</div>;
  }

  return <ServiceOverviewView services={data.studentGetServices} />;
};
