import { CircularProgress } from '@mui/material';
import { useGetApplicationsQuery } from '../../../../generated/graphql';
import { ApplicationOverviewView } from './ApplicationOverviewView';

export const ApplicationOverviewQuery = () => {
  const { loading, data, error } = useGetApplicationsQuery();

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  if (!data?.studentGetApplications) {
    return <div>Error</div>;
  }

  return <ApplicationOverviewView applications={data.studentGetApplications} />;
};
