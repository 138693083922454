import { useParams } from 'react-router-dom';
import { ApplicationNewCache } from './ApplicationNewCache';

export const ApplicationNewParams = () => {
  const { serviceId } = useParams();

  if (!serviceId) {
    throw new Error('serviceId not found');
  }

  return <ApplicationNewCache serviceId={serviceId} />;
};
