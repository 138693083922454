import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { StageTransitionAlertFragment } from '../../../../../../generated/graphql';

export const StageTransitionButtonAlertDialog = ({
  open,
  handleClose,
  handleUpdateApplication,
  loading,
  alert,
}: {
  open: boolean;
  handleClose: () => void;
  handleUpdateApplication: () => void;
  loading: boolean;
  alert: StageTransitionAlertFragment;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alert.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={1}
          width={isMobile ? '100%' : 'auto'}
        >
          <Button variant="outlined" onClick={handleClose} fullWidth={isMobile}>
            Отмена
          </Button>
          <LoadingButton
            onClick={handleUpdateApplication}
            loading={loading}
            variant={
              alert.button.variant.toLowerCase() as
                | 'text'
                | 'outlined'
                | 'contained'
            }
            color={
              alert.button.color.toLowerCase() as
                | 'error'
                | 'inherit'
                | 'primary'
                | 'secondary'
                | 'success'
                | 'info'
                | 'warning'
            }
            fullWidth={isMobile}
          >
            {alert.button.label}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
