import { RouterProvider } from 'react-router-dom';
import guestRouter from '../config/router/guestRouter';
import studentRouter from '../config/router/studentRouter';
import { useCookies } from 'react-cookie';
import { Suspense, useMemo } from 'react';
import { useGetCurrentUserQuery } from '../generated/graphql';
import { CircularProgress } from '@mui/material';
import noStudentRouter from '../config/router/noStudentRouter';
import i18n from '../config/i18n';

const UserProvider = () => {
  const { loading, error, data } = useGetCurrentUserQuery({
    onCompleted(data) {
      const currentUserLanguage =
        data.studentGetCurrentUser.language.toLowerCase();

      if (i18n.language !== currentUserLanguage) {
        i18n.changeLanguage(currentUserLanguage);
      }
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data) {
    return <div>Error</div>;
  }

  if (!data.studentGetCurrentUser.student) {
    return <RouterProvider router={noStudentRouter} />;
  }

  return <RouterProvider router={studentRouter} />;
};

export const AuthProvider = () => {
  const [cookies] = useCookies(['session_id']);

  const sessionId = useMemo(() => {
    return cookies.session_id;
  }, [cookies.session_id]);

  if (!sessionId) {
    return (
      <Suspense fallback={null}>
        <RouterProvider router={guestRouter} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={null}>
      <UserProvider />
    </Suspense>
  );
};
