import { useForm } from 'react-hook-form';
import { InputFragment } from '../../generated/graphql';
import { Grid } from '@mui/material';
import { Input } from '../../common';

export const StudentDocumentFormShow = ({
  inputs,
  data,
}: {
  inputs: InputFragment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}) => {
  const methods = useForm({
    defaultValues: data ?? {},
  });

  return inputs.length > 1 ? (
    <form noValidate>
      <Grid container spacing={2}>
        {inputs.map((input, index) => (
          <Input key={index} input={input} {...methods} show />
        ))}
      </Grid>
    </form>
  ) : null;
};
