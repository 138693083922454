import { Stack } from '@mui/material';
import { ServiceListItemFragment } from '../../../../generated/graphql';
import { Link as RouterLink } from 'react-router-dom';

export const ServiceOverviewView = ({
  services,
}: {
  services: ServiceListItemFragment[];
}) => (
  <Stack bgcolor="#f1f2f2" spacing={1}>
    {services.map((service) => (
      <Stack key={service.id} spacing={1} p={2} bgcolor="white">
        <RouterLink to={`/services/${service.id}`}>{service.name}</RouterLink>
      </Stack>
    ))}
  </Stack>
);
