import {
  Box,
  Link,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { studentRoutes } from '../../config/router/studentRouter';
import { useTranslation } from 'react-i18next';

export const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(studentRoutes);
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        top: {
          xs: 56,
          sm: 64,
        },
        zIndex: theme.zIndex.appBar,
      })}
    >
      <MUIBreadcrumbs
        sx={{
          minHeight: '56px',
          display: 'flex',
          alignItems: 'center',
          px: 2,
          borderBottom: '1px solid rgb(223, 224, 225)',
          bgcolor: '#ffffff',
        }}
      >
        {breadcrumbs.map(({ location, breadcrumb, match, key }) =>
          location.pathname === match.pathname ? (
            <Typography key={key} color="text.primary">
              {
                // @ts-expect-error fff
                breadcrumb?.props.children
                  ? // @ts-expect-error fff
                    t(breadcrumb.props.children)
                  : breadcrumb
              }
            </Typography>
          ) : (
            <Link
              key={key}
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={match}
            >
              {
                // @ts-expect-error fff
                breadcrumb?.props.children
                  ? // @ts-expect-error fff
                    t(breadcrumb.props.children)
                  : breadcrumb
              }
            </Link>
          ),
        )}
      </MUIBreadcrumbs>
    </Box>
  );
};
