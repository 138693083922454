import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Stack,
  Grid,
  Box,
} from '@mui/material';
import {
  StageTransitionFragment,
  useGetApplicationQuery,
} from '../../../../../../generated/graphql';
import { useMemo } from 'react';
import { StageTransitionButton } from '../../../StageTransitionButton';
import { UserCard } from '../../../../../UserCard';
import { StudentCard } from '../../../../../StudentCard';
import { FormCard } from '../../../../../FormCard';
import { StudentDocumentCard } from '../../../../../StudentDocumentCard';

interface StageTransitionFragmentModified extends StageTransitionFragment {
  disabled?: boolean;
}

export const StageActionButtonCorrectionDialog = ({
  open,
  handleClose,
  applicationId,
  transitions,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  transitions: StageTransitionFragment[];
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useGetApplicationQuery({
    variables: {
      id: applicationId,
    },
  });

  const verificationState = useMemo<{
    isPending: boolean;
  }>(() => {
    const { form, student, studentDocuments } =
      data!.studentGetApplication.data;

    return {
      isPending:
        (form.verification?.error && !form.verification.error.corrected) ||
        (student.verification?.error &&
          !student.verification.error.corrected) ||
        studentDocuments.some(
          (studentDocument) =>
            studentDocument.verification?.error &&
            !studentDocument.verification.error.corrected,
        ),
    };
  }, [data]);

  const buttons = useMemo<StageTransitionFragmentModified[]>(() => {
    const base = transitions.filter(
      (transition) => transition.action?.position === 0,
    );

    const added: StageTransitionFragmentModified[] = transitions
      .filter((transition) => transition.action?.position === 1)
      .map((transition) => ({
        ...transition,
        disabled: verificationState.isPending,
      }));

    return [...base, ...added];
  }, [verificationState, transitions]);

  const renderTransitionButtons = () => {
    return buttons.map((transition) => (
      <StageTransitionButton
        key={transition.nextStageId}
        applicationId={applicationId}
        transition={transition}
        disabled={transition.disabled}
      />
    ));
  };

  if (!data) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle id="alert-dialog-title">Исправление данных</DialogTitle>
      <DialogContent sx={{ bgcolor: '#f3f3f3' }}>
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <UserCard user={data.studentGetApplication.data.user} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StudentCard
                student={data.studentGetApplication.data.student}
                correction={{ applicationId }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormCard
                form={data.studentGetApplication.data.form}
                inputs={data.studentGetApplication.service.inputs}
                correction={{ applicationId }}
              />
            </Grid>
          </Grid>
          {data.studentGetApplication.data.studentDocuments.length > 0 && (
            <Box mt={2}>
              <Grid container spacing={2}>
                {data.studentGetApplication.data.studentDocuments.map(
                  (studentDocument) => (
                    <Grid
                      key={studentDocument.data.category}
                      item
                      xs={12}
                      md={6}
                      lg={4}
                    >
                      <StudentDocumentCard
                        studentDocument={studentDocument}
                        correction={{ applicationId }}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={1}
          width={isMobile ? '100%' : 'auto'}
        >
          {renderTransitionButtons()}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
