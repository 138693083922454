import { Box, Typography, Stack } from '@mui/material';
import { ApplicationFragment } from '../../../../generated/graphql';
import {
  FormCard,
  StudentCard,
  UserCard,
  ApplicationHistory,
  StageTransitionMenu,
  ApplicationDocuments,
  StudentDocumentCard,
} from '../../../../common';

export const ApplicationShowView = ({
  application,
}: {
  application: ApplicationFragment;
}) => (
  <Box>
    <Stack bgcolor="#f1f2f2" spacing={1} pb={1}>
      {/* <Alert
        icon={false}
        severity={
          application.interface.alert.severity as
            | 'success'
            | 'info'
            | 'warning'
            | 'error'
        }
        sx={{
          borderRadius: 0,
        }}
      >
        <AlertTitle>{application.interface.alert.title}</AlertTitle>
        {application.interface.alert.description}
      </Alert> */}

      <Stack spacing={1} p={2} bgcolor="white">
        <Typography fontWeight="500">Основное</Typography>
        <Box>
          <Typography>Номер</Typography>
          <Typography>{application.serialNumber}</Typography>
        </Box>
        <Box>
          <Typography>Услуга</Typography>
          <Typography>{application.data.service.name}</Typography>
        </Box>
        <Box>
          <Typography>Статус</Typography>
          <Typography>{application.stage.name}</Typography>
        </Box>
      </Stack>

      <ApplicationDocuments applicationDocuments={application.documents} />

      <UserCard user={application.data.user} />

      <StudentCard student={application.data.student} />

      <FormCard
        form={application.data.form}
        inputs={application.service.inputs}
      />

      {application.data.studentDocuments.map((studentDocument) => (
        <StudentDocumentCard
          key={studentDocument.data.category}
          studentDocument={studentDocument}
        />
      ))}

      <ApplicationHistory updates={application.updates} />
    </Stack>

    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        bgcolor: '#ffffff',
      }}
    >
      <StageTransitionMenu
        applicationId={application.id}
        transitions={application.stage.transitions}
        action={application.stage.action}
      />
    </Box>
  </Box>
);
