import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { FormSnapshotFragment, InputFragment } from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';
import { useState } from 'react';
import { FormEdit } from './FormEdit';
import { FormShow } from './FormShow';
import { useTranslation } from 'react-i18next';

export const FormCard = ({
  form,
  inputs,
  correction,
}: {
  form: FormSnapshotFragment;
  inputs: InputFragment[];
  correction?: {
    applicationId: string;
  };
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleShow = () => {
    setEdit(false);
  };

  const renderCorrectionMenu = () => {
    return edit ? (
      <CardActions>
        <Stack spacing={1} width="100%">
          <Button onClick={handleShow} variant="outlined">
            Назад
          </Button>
        </Stack>
      </CardActions>
    ) : (
      <CardActions>
        <Button onClick={handleEdit} variant="outlined" fullWidth>
          Изменить
        </Button>
      </CardActions>
    );
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">
              {t('application.card.headers.form')}
            </Typography>
            <VerificationStatus verification={form.verification} />
          </Box>
          {form.verification?.error && (
            <Alert
              severity={form.verification.error.corrected ? 'warning' : 'error'}
            >
              <AlertTitle>
                {form.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {form.verification.error.message}
            </Alert>
          )}
          {correction && edit ? (
            <FormEdit
              data={form.data}
              inputs={inputs}
              applicationId={correction.applicationId}
              handleShow={handleShow}
            />
          ) : (
            <FormShow data={form.data} inputs={inputs} />
          )}
        </Stack>
      </CardContent>
      {correction && renderCorrectionMenu()}
    </Card>
  );
};
