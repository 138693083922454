import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { UserSnapshotFragment } from '../../generated/graphql';
import { iinToDate } from '../../utils';
import { format } from 'date-fns';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useTranslation } from 'react-i18next';
import { ru } from 'date-fns/locale';

export const UserCard = ({ user }: { user: UserSnapshotFragment }) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">
              {t('application.card.headers.user')}
            </Typography>
            <Tooltip title="Данные были подтверждены ЭЦП" placement="right">
              <VerifiedIcon sx={{ ml: 1, fontSize: 20 }} color="primary" />
            </Tooltip>
          </Box>
          <Box>
            <Typography>ИИН</Typography>
            <Typography>{user.iin}</Typography>
          </Box>
          <Box>
            <Typography>ФИО</Typography>
            <Typography>
              {`${user.lastName} ${user.firstName} ${user.middleName}`}
            </Typography>
          </Box>
          <Box>
            <Typography>Гражданство</Typography>
            <Typography>{user.country}</Typography>
          </Box>
          <Box>
            <Typography>Дата рождения</Typography>
            <Typography>
              {format(iinToDate(user.iin), 'PPP', {
                locale: ru,
              })}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
