import { useGetQrSignQuery, useLoadQrQuery } from '../../generated/graphql';
import { CircularProgress } from '@mui/material';

interface QrCodeProps {
  qrCode: string;
  dataURL: string;
  signURL: string;
}

export const QrCode = ({ qrCode, dataURL, signURL }: QrCodeProps) => {
  const { data, loading: loadQrLoading } = useLoadQrQuery({
    variables: {
      dataURL,
    },
  });
  const { loading: getQrSignLoading } = useGetQrSignQuery({
    variables: {
      signURL,
      // @ts-expect-error fff
      nonce: data?.loadQr,
    },
    skip: loadQrLoading,
  });

  return getQrSignLoading ? (
    <CircularProgress />
  ) : (
    <img src={`data:image/jpeg;base64,${qrCode}`} />
  );
};
