import { Stack, Typography } from '@mui/material';
import { ApplicationListItemFragment } from '../../../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';

export const ApplicationOverviewView = ({
  applications,
}: {
  applications: ApplicationListItemFragment[];
}) => {
  const navigate = useNavigate();

  const handleClick = (applicationId: string): void => {
    navigate(`/applications/${applicationId}`);
  };

  return (
    <Stack bgcolor="#f1f2f2" spacing={1} pb={1}>
      {applications.map((applicationListItem) => (
        <Stack
          spacing={1}
          p={2}
          bgcolor="white"
          key={applicationListItem.id}
          onClick={() => handleClick(applicationListItem.id)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography>{applicationListItem.service.name}</Typography>
          <Typography>{applicationListItem.stage.name}</Typography>
          <Typography variant="body2" color="gray">
            {formatDistanceToNow(parseISO(applicationListItem.createdAt), {
              addSuffix: true,
              locale: ru,
            })}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
