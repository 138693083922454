import {
  InputFragment,
  InputType,
  StudentDataFragment,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  getValueByPath,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { Grid } from '@mui/material';

export const StudentCardShow = ({
  data,
  inputs,
}: {
  data: StudentDataFragment;
  inputs: InputFragment[];
}) => {
  const methods = useForm({
    defaultValues: {
      university: {
        id: data.university.id,
        name: data.university.name,
        code: data.university.code,
      },
      ...updateDefaultValuesWithData(
        extractDefaultValuesFromInputs(inputs),
        data,
      ),
    },
  });

  return (
    <form noValidate>
      <Grid container spacing={2}>
        <Input
          input={{
            name: 'university',
            type: InputType.University,
            position: 0,
            label: 'Университет',
            params: {
              defaultValue: null,
              resetOnChange: [
                { name: 'program', value: null },
                { name: 'faculty', value: null },
                { name: 'speciality', value: null },
              ],
            },
            rules: {
              required: {
                value: true,
                message: 'Обязательное поле',
              },
            },
          }}
          show
          {...methods}
        />

        {inputs
          .filter((input) => getValueByPath(data, input.name) !== undefined)
          // .sort((a, b) => a.position - b.position)
          .map((input, index) => (
            <Input key={index} input={input} show {...methods} />
          ))}
      </Grid>
    </form>
  );
};
