import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { EGOV_MOBILE_INSTRUCTIONS } from '../../constants';

export const EgovMobileIntructionsDesktop = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevCurrentStep) => {
      if (prevCurrentStep >= EGOV_MOBILE_INSTRUCTIONS.length - 1) {
        return prevCurrentStep;
      }

      return prevCurrentStep + 1;
    });
  };

  const prevStep = () => {
    setCurrentStep((prevCurrentStep) => {
      if (prevCurrentStep <= 0) {
        return prevCurrentStep;
      }

      return prevCurrentStep - 1;
    });
  };

  const imgSrc = useMemo(() => {
    return EGOV_MOBILE_INSTRUCTIONS[currentStep].img;
  }, [currentStep]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Box minHeight="60px" display="flex" justifyContent="center">
        <Typography textAlign="center" fontSize={20} fontWeight={500}>
          Шаг {currentStep + 1}. {EGOV_MOBILE_INSTRUCTIONS[currentStep].text}
        </Typography>
      </Box>

      <Stack direction="row" flex={1} spacing={1}>
        <Button fullWidth onClick={prevStep} disabled={currentStep === 0}>
          <NavigateBeforeIcon />
        </Button>
        <Box
          sx={{
            height: {
              xs: 'calc(100dvh - 56px - 24px - 90px - 60px - 16px)',
              sm: 'calc(100dvh - 64px - 24px - 60px - 16px)',
            },
          }}
        >
          <img
            src={imgSrc}
            alt={`Шаг ${currentStep + 1}`}
            style={{
              height: '100%',
            }}
          />
        </Box>
        <Button
          fullWidth
          onClick={nextStep}
          disabled={currentStep === EGOV_MOBILE_INSTRUCTIONS.length - 1}
        >
          <NavigateNextIcon />
        </Button>
      </Stack>
    </Box>
  );
};
