import { useGetServiceQuery } from '../../../../generated/graphql';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ApplicationNewView } from './ApplicationNewView';

export const ApplicationNewQuery = ({ serviceId }: { serviceId: string }) => {
  const { data, loading, error } = useGetServiceQuery({
    variables: {
      id: serviceId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data?.studentGetService) {
    return <Navigate to="/services" />;
  }

  return <ApplicationNewView service={data.studentGetService} />;
};
