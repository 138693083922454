import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { StudentSnapshotFragment } from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { StudentCardShow } from './StudentCardShow';
import { StudentCardEdit } from './StudentCardEdit';

export const StudentCard = ({
  student,
  correction,
}: {
  student: StudentSnapshotFragment;
  correction?: {
    applicationId: string;
  };
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleShow = () => {
    setEdit(false);
  };

  const renderCorrectionMenu = () => {
    return edit ? (
      <CardActions>
        <Stack spacing={1} width="100%">
          <Button onClick={handleShow} variant="outlined">
            Назад
          </Button>
        </Stack>
      </CardActions>
    ) : (
      <CardActions>
        <Button onClick={handleEdit} variant="outlined" fullWidth>
          Изменить
        </Button>
      </CardActions>
    );
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">
              {t('application.card.headers.student')}
            </Typography>
            <VerificationStatus verification={student.verification} />
          </Box>
          {student.verification?.error && (
            <Alert
              severity={
                student.verification.error.corrected ? 'warning' : 'error'
              }
            >
              <AlertTitle>
                {student.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {student.verification.error.message}
            </Alert>
          )}
          {correction && edit ? (
            <StudentCardEdit
              data={student.data}
              inputs={student.inputs}
              applicationId={correction.applicationId}
              handleShow={handleShow}
            />
          ) : (
            <StudentCardShow data={student.data} inputs={student.inputs} />
          )}
        </Stack>
      </CardContent>
      {correction && renderCorrectionMenu()}
    </Card>
  );
};
