import { Box, Grid, Typography } from '@mui/material';
import imagehome from '../imagehome.png';
import { useTranslation } from 'react-i18next';

export const Second = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md="auto"
        order={{
          xs: 1,
          md: 0,
        }}
      >
        <Box
          height={{
            xs: 'auto',
            sm: 'calc(100dvh - 64px)',
          }}
          sx={(theme) => ({
            img: {
              [theme.breakpoints.up('xs')]: {
                height: '100%',
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                height: '100%',
                width: 'auto',
              },
            },
          })}
        >
          <img src={imagehome} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md
        order={{
          xs: 0,
          md: 1,
        }}
      >
        <Box
          height={{
            xs: 'auto',
            sm: 'calc(100dvh - 64px)',
          }}
          px={{
            xs: 2,
            md: 5,
          }}
          py={{
            xs: 8,
            md: 0,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          bgcolor="white"
          color="rgb(52, 120, 247)"
          textAlign="center"
        >
          <Typography
            fontSize={{
              xs: 15,
              md: 50,
            }}
            fontWeight="bold"
          >
            {t('home.convenience')}
          </Typography>
          <Box
            color="#333333"
            mt={{
              xs: 2,
              md: 10,
            }}
          >
            <Typography
              fontSize={{
                xs: 15,
                md: 35,
              }}
              fontWeight="bold"
            >
              {t('home.student_system')}
            </Typography>
            <Typography
              fontSize={{
                xs: 10,
                md: 25,
              }}
            >
              {t('home.student_certificate')}
            </Typography>
          </Box>
          <Typography
            fontSize={{
              xs: 15,
              md: 50,
            }}
            fontWeight="bold"
          >
            {t('home.or')}
          </Typography>
          <Box sx={{ color: 'black' }}>
            <Typography
              fontSize={{
                xs: 15,
                md: 35,
              }}
              fontWeight="bold"
            >
              {t('home.specialist_system')}
            </Typography>
          </Box>
          <Box sx={{ color: 'black' }}>
            <Typography
              fontSize={{
                xs: 10,
                md: 25,
              }}
            >
              {t('home.specialist_application')}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
