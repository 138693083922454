import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  useCreateStudentDocumentMutation,
  useGetSignedUrlsForFileUploadLazyQuery,
  DocumentFragment,
} from '../../generated/graphql';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { documentCategoryVar } from '../../config/apollo/client';
import { StudentDocumentFormNew } from './StudentDocumentFormNew';

export const StudentDocumentDialogNew = ({
  document,
}: {
  document: DocumentFragment;
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [createStudentDocument, { loading }] = useCreateStudentDocumentMutation(
    {
      update(cache, result) {
        const newStudentDocument = result.data?.studentCreateStudentDocument;

        if (!newStudentDocument) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
          {
            query: GetCurrentUserDocument,
          },
          (data) =>
            data && data.studentGetCurrentUser.student
              ? {
                  studentGetCurrentUser: {
                    ...data.studentGetCurrentUser,
                    student: {
                      ...data.studentGetCurrentUser.student!,
                      documents: [
                        ...data.studentGetCurrentUser.student.documents,
                        newStudentDocument,
                      ],
                    },
                  },
                }
              : data,
        );
      },
    },
  );
  const [getSignedUrls] = useGetSignedUrlsForFileUploadLazyQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    documentCategoryVar(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateDocument = async ({ files, ...form }: any) => {
    setUploading(true);
    const fileList = files as FileList;

    const { data } = await getSignedUrls({
      variables: {
        documentCategory: document.category,
        fileNames: Array.from(fileList).map((file) => file.name),
      },
    });

    if (!data) {
      throw Error('Something went wrong');
    }

    const payloadFiles = await Promise.all(
      data.studentGetSignedUrlsForFileUpload.map(
        async ({ signedUrl, key }, index) => {
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': fileList[index].type,
            },
            body: fileList[index],
          });

          return {
            key,
            name: fileList[index].name,
            type: fileList[index].type,
          };
        },
      ),
    ).finally(() => {
      setUploading(false);
    });

    createStudentDocument({
      variables: {
        documentCategory: document.category,
        data: form,
        files: payloadFiles,
      },
    });
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{document.name}</DialogTitle>
      <DialogContent>
        <StudentDocumentFormNew
          handleSubmit={handleCreateDocument}
          loading={uploading || loading}
          inputs={document.inputs}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
          width={{
            xs: '100%',
            md: 'auto',
          }}
          spacing={1}
        >
          <Button
            fullWidth={isMobile}
            onClick={handleClose}
            variant="outlined"
            disabled={uploading || loading}
          >
            Отмена
          </Button>
          <LoadingButton
            fullWidth={isMobile}
            type="submit"
            form="STUDENT_DOCUMENT_NEW"
            loading={uploading || loading}
            variant="contained"
          >
            Добавить
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
