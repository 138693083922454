import { Navigate, createBrowserRouter } from 'react-router-dom';
import { ServiceOverview } from '../../pages/student/service/overview';
import { ApplicationOverview } from '../../pages/student/application/overview';
import { StudentLayout } from '../../components/StudentLayout/StudentLayout';
import { ApplicationShow } from '../../pages/student/application/show';
import { ApplicationNew } from '../../pages/student/application/new';
import { DynamicServiceBreadcrumb } from './DynamicServiceBreadcrumb';
import { ProfileOverview } from '../../pages/student/profile/overview';
import { StudentDocumentOverview } from '../../pages/student/student-document/overview';

export const studentRoutes = [
  {
    path: '/',
    element: <StudentLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/services" />,
        breadcrumb: null,
      },
      {
        path: '/profile',
        element: <ProfileOverview />,
        breadcrumb: 'breadcrumb.profile.overview',
      },
      {
        path: '/documents',
        element: <StudentDocumentOverview />,
        breadcrumb: 'breadcrumb.document.overview',
      },
      {
        path: '/services',
        element: <ServiceOverview />,
        breadcrumb: 'breadcrumb.service.overview',
      },
      {
        path: '/services/:serviceId',
        element: <ApplicationNew />,
        breadcrumb: DynamicServiceBreadcrumb,
      },
      {
        path: '/applications',
        element: <ApplicationOverview />,
        breadcrumb: 'breadcrumb.application.overview',
      },
      {
        path: '/applications/:applicationId',
        element: <ApplicationShow />,
        breadcrumb: 'breadcrumb.application.show',
      },
      {
        path: '*',
        element: <Navigate to="/services" />,
      },
    ],
  },
];

const studentRouter = createBrowserRouter(studentRoutes);

export default studentRouter;
