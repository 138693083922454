import { Box, Grid, Typography } from '@mui/material';
import image4page from '../image4page.png';
import { useTranslation } from 'react-i18next';

export const Fourth = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md="auto"
        order={{
          xs: 1,
          md: 0,
        }}
      >
        <Box
          height={{
            xs: 'auto',
            sm: 'calc(100dvh - 64px)',
          }}
          sx={(theme) => ({
            img: {
              [theme.breakpoints.up('xs')]: {
                height: '100%',
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                height: '100%',
                width: 'auto',
              },
            },
          })}
        >
          <img src={image4page} />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md
        order={{
          xs: 0,
          md: 1,
        }}
      >
        <Box
          height={{
            xs: 'auto',
            sm: 'calc(100dvh - 64px)',
          }}
          px={{
            xs: 2,
            md: 5,
          }}
          py={{
            xs: 8,
            md: 0,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          bgcolor="white"
          color="rgb(52, 120, 247)"
          textAlign="center"
        >
          <Typography
            fontSize={{
              xs: 20,
              md: 50,
            }}
            dangerouslySetInnerHTML={{
              __html: t('home.visualization'),
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
