export const REGIONS: string[] = [
  'city_astana',
  'city_almaty',
  'city_shymkent',
  'region_abai',
  'region_aqmola',
  'region_aqtobe',
  'region_almaty',
  'region_atyrau',
  'region_east',
  'region_jambyl',
  'region_jetisu',
  'region_west',
  'region_qaraghandy',
  'region_qostanai',
  'region_qyzylorda',
  'region_manghystau',
  'region_pavlodar',
  'region_north',
  'region_turkistan',
  'region_ulytau',
];
