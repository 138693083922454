import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  InputFragment,
  StudentDocumentDataFragment,
  useCorrectStudentDocumentFormMutation,
  useGetSignedUrlsForFileUploadLazyQuery,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';

export const StudentDocumentCardEdit = ({
  data: studentDocumentData,
  inputs,
  applicationId,
  handleShow,
}: {
  data: StudentDocumentDataFragment;
  inputs: InputFragment[];
  applicationId: string;
  handleShow: () => void;
}) => {
  const methods = useForm<StudentDocumentDataFragment>({
    defaultValues: updateDefaultValuesWithData(
      extractDefaultValuesFromInputs(inputs),
      studentDocumentData.data,
    ),
  });

  const [correctStudentDocumentForm, { loading }] =
    useCorrectStudentDocumentFormMutation();

  const [getSignedUrls] = useGetSignedUrlsForFileUploadLazyQuery();

  const [uploading, setUploading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (formData: any) => {
    const { files, ...form } = formData;

    setUploading(true);

    const fileList = files as FileList;

    const { data: signedUrlsData } = await getSignedUrls({
      variables: {
        documentCategory: studentDocumentData.category,
        fileNames: Array.from(fileList).map((file) => file.name),
      },
    });

    if (!signedUrlsData) {
      throw Error('Something went wrong');
    }

    const payloadFiles = await Promise.all(
      signedUrlsData.studentGetSignedUrlsForFileUpload.map(
        async ({ signedUrl, key }, index) => {
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': fileList[index].type,
            },
            body: fileList[index],
          });

          return {
            key,
            name: fileList[index].name,
            type: fileList[index].type,
          };
        },
      ),
    ).finally(() => {
      setUploading(false);
    });

    correctStudentDocumentForm({
      variables: {
        applicationId: applicationId,
        documentCategory: studentDocumentData.category,
        data: form,
        files: payloadFiles,
      },
      update(cache, result) {
        const newStudentDocumentSnapshot =
          result.data?.studentCorrectStudentDocumentForm;

        if (!newStudentDocumentSnapshot) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetApplication: {
                  ...data.studentGetApplication,
                  data: {
                    ...data.studentGetApplication.data,
                    studentDocuments:
                      data.studentGetApplication.data.studentDocuments.map(
                        (studentDocumentSnapshot) =>
                          studentDocumentSnapshot.data.category ===
                          studentDocumentData.category
                            ? newStudentDocumentSnapshot
                            : studentDocumentSnapshot,
                      ),
                  },
                },
              };
            }
          },
        );

        cache.updateQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
          {
            query: GetCurrentUserDocument,
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetCurrentUser: {
                  ...data.studentGetCurrentUser,
                  student: {
                    ...data.studentGetCurrentUser.student!,
                    documents:
                      data.studentGetCurrentUser.student!.documents.map(
                        (studentDocument) =>
                          studentDocument.data.category === formData.category
                            ? {
                                ...studentDocument,
                                data: newStudentDocumentSnapshot.data.data,
                                files: newStudentDocumentSnapshot.data.files,
                              }
                            : studentDocument,
                      ),
                  },
                },
              };
            }
          },
        );
      },
      onCompleted() {
        handleShow();
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={2}>
            {inputs.map((input, index) => (
              <Input
                key={index}
                input={input}
                disabled={loading || uploading}
                {...methods}
              />
            ))}
          </Grid>
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={loading || uploading}
          fullWidth
        >
          Сохранить
        </LoadingButton>
      </Stack>
    </form>
  );
};
